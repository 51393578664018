/**
 *
 * Notifications/List
 *
 */

import React, { useState, useEffect } from 'react'

// NPM Libraries
import VisibilitySensor from 'react-visibility-sensor'
import PropTypes from 'prop-types'

// Graphql
import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'

// Components
import Spinner from 'components/Spinner'
import NotificationListItem from 'components/Notifications/List/Item'

const LOAD_NOTIFICATIONS = gql`
	query loadNotifications($limit: Int, $actionable: Boolean, $user_id: ID) {
		notifications(limit: $limit, actionable: $actionable, user_id: $user_id) {
			_total
			notifications {
				id
				created_at
				read_status
				read_status_index
				actionable
				link
				output {
					icon
					text
				}
				owner {
					id
					full_name
				}
			}
		}
	}
`

function List({ actionable, user_id, initial_amount, hide_mark_as_read, setLoaded }) {
	const [notification_list, setNotificationList] = useState([])
	const [notification_total, setNotificationTotal] = useState(0)
	const [amount_to_load, setAmountToLoad] = useState(initial_amount)

	const {
		data: {
			notifications: { notifications, _total },
		},
		loading,
	} = useQuery(LOAD_NOTIFICATIONS, {
		channels: [`notifications_${user_id}`],
		variables: {
			limit: amount_to_load,
			actionable,
			user_id,
		},
		default_data: {
			notifications: {
				notifications: [],
			},
		},
	})

	useEffect(() => {
		if (notifications.length > 1 && !loading && setLoaded) {
			setLoaded(true)
		}
	}, [notifications, loading])

	useEffect(() => {
		if (notifications.length > 1) {
			setNotificationList([...notifications])
		}

		if (_total && notification_total !== _total) {
			setNotificationTotal(_total)
		}
	}, [notifications])

	if (notification_list.length === 0 && loading) {
		return (
			<div className="py-3" style={{ height: 10 }}>
				<Spinner scale={2} />
			</div>
		)
	}

	return (
		<ul className="list-group list-group-flush">
			{notification_list.map((notification) => (
				<li className="list-group-item p-0" key={notification.id}>
					<NotificationListItem notification={notification} hide_mark_as_read={hide_mark_as_read} />
				</li>
			))}

			<VisibilitySensor
				partialVisibility
				onChange={(visibility) => visibility && !loading && setAmountToLoad(amount_to_load + initial_amount)}
			>
				<div className="py-4 mb-2" style={{ height: loading ? '50px' : '0' }}>
					{loading && <Spinner scale={0.5} />}
				</div>
			</VisibilitySensor>
		</ul>
	)
}

List.propTypes = {
	initial_amount: PropTypes.number,
	user_id: PropTypes.string,
	actionable: PropTypes.bool,
	hide_mark_as_read: PropTypes.bool,
	setLoaded: PropTypes.func,
}

List.defaultProps = {
	initial_amount: 20,
}

export default List
